/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateSubscriptionPlan } from '../models/create-subscription-plan';
import { CreateSubscriptionRequest } from '../models/create-subscription-request';
import { Plan } from '../models/plan';
import { PlanResponse } from '../models/plan-response';
import { Subscription } from '../models/subscription';
import { UpdatePlanRequest } from '../models/update-plan-request';
import { UpdateSubscriptionRequest } from '../models/update-subscription-request';

@Injectable({
  providedIn: 'root',
})
export class MotivSubscriptionsApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getMotivPlans
   */
  static readonly GetMotivPlansPath = '/api/v1/plans';

  /**
   * List all available plans.
   *
   * Retrieve a list of all available subscription plans.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMotivPlans()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMotivPlans$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Plan>> {

    const rb = new RequestBuilder(this.rootUrl, MotivSubscriptionsApiService.GetMotivPlansPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Plan>;
      })
    );
  }

  /**
   * List all available plans.
   *
   * Retrieve a list of all available subscription plans.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMotivPlans$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMotivPlans(params?: {
    context?: HttpContext
  }
): Observable<Plan> {

    return this.getMotivPlans$Response(params).pipe(
      map((r: StrictHttpResponse<Plan>) => r.body as Plan)
    );
  }

  /**
   * Path part for operation createMotivPlans
   */
  static readonly CreateMotivPlansPath = '/api/v1/plans';

  /**
   * Create a new subscription plan.
   *
   * Create new subscription plan.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMotivPlans()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMotivPlans$Response(params: {
    context?: HttpContext
    body: CreateSubscriptionPlan
  }
): Observable<StrictHttpResponse<PlanResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MotivSubscriptionsApiService.CreateMotivPlansPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlanResponse>;
      })
    );
  }

  /**
   * Create a new subscription plan.
   *
   * Create new subscription plan.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createMotivPlans$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMotivPlans(params: {
    context?: HttpContext
    body: CreateSubscriptionPlan
  }
): Observable<PlanResponse> {

    return this.createMotivPlans$Response(params).pipe(
      map((r: StrictHttpResponse<PlanResponse>) => r.body as PlanResponse)
    );
  }

  /**
   * Path part for operation getMotivPlansById
   */
  static readonly GetMotivPlansByIdPath = '/api/v1/plans/{planId}';

  /**
   * Get plan details.
   *
   * Retrieve details of a specific plan.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMotivPlansById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMotivPlansById$Response(params: {
    planId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Plan>> {

    const rb = new RequestBuilder(this.rootUrl, MotivSubscriptionsApiService.GetMotivPlansByIdPath, 'get');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Plan>;
      })
    );
  }

  /**
   * Get plan details.
   *
   * Retrieve details of a specific plan.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMotivPlansById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMotivPlansById(params: {
    planId: string;
    context?: HttpContext
  }
): Observable<Plan> {

    return this.getMotivPlansById$Response(params).pipe(
      map((r: StrictHttpResponse<Plan>) => r.body as Plan)
    );
  }

  /**
   * Path part for operation removeMotivPlansById
   */
  static readonly RemoveMotivPlansByIdPath = '/api/v1/plans/{planId}';

  /**
   * Delete a subscription plan.
   *
   * Delete a specific subscription plan.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeMotivPlansById()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeMotivPlansById$Response(params: {
    planId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MotivSubscriptionsApiService.RemoveMotivPlansByIdPath, 'delete');
    if (params) {
      rb.path('planId', params.planId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a subscription plan.
   *
   * Delete a specific subscription plan.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeMotivPlansById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeMotivPlansById(params: {
    planId: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.removeMotivPlansById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateMotivPlansById
   */
  static readonly UpdateMotivPlansByIdPath = '/api/v1/plans/{planId}';

  /**
   * Update plan details.
   *
   * Update details of a specific plan.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMotivPlansById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMotivPlansById$Response(params: {
    planId: string;
    context?: HttpContext
    body: UpdatePlanRequest
  }
): Observable<StrictHttpResponse<Subscription>> {

    const rb = new RequestBuilder(this.rootUrl, MotivSubscriptionsApiService.UpdateMotivPlansByIdPath, 'patch');
    if (params) {
      rb.path('planId', params.planId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Subscription>;
      })
    );
  }

  /**
   * Update plan details.
   *
   * Update details of a specific plan.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateMotivPlansById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMotivPlansById(params: {
    planId: string;
    context?: HttpContext
    body: UpdatePlanRequest
  }
): Observable<Subscription> {

    return this.updateMotivPlansById$Response(params).pipe(
      map((r: StrictHttpResponse<Subscription>) => r.body as Subscription)
    );
  }

  /**
   * Path part for operation getMotivSubscriptions
   */
  static readonly GetMotivSubscriptionsPath = '/api/v1/subscriptions';

  /**
   * List all subscriptions for a user, use shure_userId from access_token.
   *
   * Retrieve a list of all subscriptions for a user, use shure_userId from access_token.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMotivSubscriptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMotivSubscriptions$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Subscription>> {

    const rb = new RequestBuilder(this.rootUrl, MotivSubscriptionsApiService.GetMotivSubscriptionsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Subscription>;
      })
    );
  }

  /**
   * List all subscriptions for a user, use shure_userId from access_token.
   *
   * Retrieve a list of all subscriptions for a user, use shure_userId from access_token.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMotivSubscriptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMotivSubscriptions(params?: {
    context?: HttpContext
  }
): Observable<Subscription> {

    return this.getMotivSubscriptions$Response(params).pipe(
      map((r: StrictHttpResponse<Subscription>) => r.body as Subscription)
    );
  }

  /**
   * Path part for operation createMotivSubscriptions
   */
  static readonly CreateMotivSubscriptionsPath = '/api/v1/subscriptions';

  /**
   * Create a new subscription.
   *
   * Create a new subscription for a user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMotivSubscriptions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMotivSubscriptions$Response(params: {
    context?: HttpContext
    body: CreateSubscriptionRequest
  }
): Observable<StrictHttpResponse<Subscription>> {

    const rb = new RequestBuilder(this.rootUrl, MotivSubscriptionsApiService.CreateMotivSubscriptionsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Subscription>;
      })
    );
  }

  /**
   * Create a new subscription.
   *
   * Create a new subscription for a user.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createMotivSubscriptions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMotivSubscriptions(params: {
    context?: HttpContext
    body: CreateSubscriptionRequest
  }
): Observable<Subscription> {

    return this.createMotivSubscriptions$Response(params).pipe(
      map((r: StrictHttpResponse<Subscription>) => r.body as Subscription)
    );
  }

  /**
   * Path part for operation getMotivSubscriptionsById
   */
  static readonly GetMotivSubscriptionsByIdPath = '/api/v1/subscriptions/{subscriptionId}';

  /**
   * Get subscription details.
   *
   * Retrieve details of a specific subscription.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMotivSubscriptionsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMotivSubscriptionsById$Response(params: {
    subscriptionId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Subscription>> {

    const rb = new RequestBuilder(this.rootUrl, MotivSubscriptionsApiService.GetMotivSubscriptionsByIdPath, 'get');
    if (params) {
      rb.path('subscriptionId', params.subscriptionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Subscription>;
      })
    );
  }

  /**
   * Get subscription details.
   *
   * Retrieve details of a specific subscription.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMotivSubscriptionsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMotivSubscriptionsById(params: {
    subscriptionId: string;
    context?: HttpContext
  }
): Observable<Subscription> {

    return this.getMotivSubscriptionsById$Response(params).pipe(
      map((r: StrictHttpResponse<Subscription>) => r.body as Subscription)
    );
  }

  /**
   * Path part for operation removeMotivSubscriptionsById
   */
  static readonly RemoveMotivSubscriptionsByIdPath = '/api/v1/subscriptions/{subscriptionId}';

  /**
   * Cancel subscription.
   *
   * Cancel a specific subscription.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeMotivSubscriptionsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeMotivSubscriptionsById$Response(params: {
    subscriptionId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MotivSubscriptionsApiService.RemoveMotivSubscriptionsByIdPath, 'delete');
    if (params) {
      rb.path('subscriptionId', params.subscriptionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Cancel subscription.
   *
   * Cancel a specific subscription.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeMotivSubscriptionsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeMotivSubscriptionsById(params: {
    subscriptionId: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.removeMotivSubscriptionsById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateMotivSubscriptionsById
   */
  static readonly UpdateMotivSubscriptionsByIdPath = '/api/v1/subscriptions/{subscriptionId}';

  /**
   * Update subscription.
   *
   * Update an existing subscription.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMotivSubscriptionsById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMotivSubscriptionsById$Response(params: {
    subscriptionId: string;
    context?: HttpContext
    body: UpdateSubscriptionRequest
  }
): Observable<StrictHttpResponse<Subscription>> {

    const rb = new RequestBuilder(this.rootUrl, MotivSubscriptionsApiService.UpdateMotivSubscriptionsByIdPath, 'patch');
    if (params) {
      rb.path('subscriptionId', params.subscriptionId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Subscription>;
      })
    );
  }

  /**
   * Update subscription.
   *
   * Update an existing subscription.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateMotivSubscriptionsById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMotivSubscriptionsById(params: {
    subscriptionId: string;
    context?: HttpContext
    body: UpdateSubscriptionRequest
  }
): Observable<Subscription> {

    return this.updateMotivSubscriptionsById$Response(params).pipe(
      map((r: StrictHttpResponse<Subscription>) => r.body as Subscription)
    );
  }

}
